import { actionService, eventService } from '@/services';
import ToastHelper from '@/shared/helpers/toast';
import { mapState } from 'vuex';

export default {
  data() {
    return {
      automations: [],
    };
  },
  computed: {
    ...mapState({
      school: state => state.school.selectedSchool,
    }),
  },
  methods: {
    async automationSaved(attrs) {
      this.isLoading = true;
      try {
        const { events } = await eventService.get([{ key: 'trigger', value: 'ended_lesson' }]);
        const hermes_event_id = events.length
          ? events[0].id
          : (await eventService.create({ school_id: this.school.id, trigger: 'ended_lesson' })).id;

        const newAutomation = await actionService.createHermesAction({
          active: true,
          conditions: {
            operator: 'and',
            filters: [{ compare: 'equals', key: 'lesson_id', value: this.lesson.id }],
          },
          scope: 'User',
          hermes_event_id,
          ...attrs,
        });
        this.automations.push(newAutomation);
      } catch (e) {
        ToastHelper.dangerMessage(this.$t('sparkmembers.contents.views.editor.toast.error'));
      }
      this.isLoading = false;
    },
    async automationRemoved(id) {
      this.isLoading = true;
      try {
        await actionService.deleteHermesAction(id);
        this.automations = this.automations.filter(aut => aut.id !== id);
      } catch (e) {
        ToastHelper.dangerMessage(this.$t('sparkmembers.contents.views.editor.toast.error'));
      }
      this.isLoading = false;
    },
    async automationChanged(attrs) {
      try {
        await actionService.updateHermesAction(attrs);
        this.loadContent();
      } catch (error) {
        ToastHelper.dangerMessage(this.$t('sparkmembers.contents.views.editor.toast.error'));
      }
    },
    async loadAutomationsFromSelectedLesson() {
      try {
        const { events } = await eventService.get([{ key: 'trigger', value: 'ended_lesson' }]);
        if (events.length) {
          const actions = events[0].actions;
          const lessonAutomations = actions.filter(({ conditions }) =>
            conditions.filters.some(({ value }) => value === this.lesson.id)
          );
          this.automations = lessonAutomations;
        }
      } catch (error) {
        ToastHelper.dangerMessage(this.$t('sparkmembers.contents.views.editor.toast.error'));
      }
    },
  },
};
